import { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

export const Contact = (props) => {
  const formRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [submitting, setSubmitting] = useState(false); // Track if form is submitting

  const departments = [
    "Sales",
    "HR",
    "Customer Support",
    "Finance",
    "Marketing",
    "Product Development",
    "Other"
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // Disable the button and start the form submission
    setSubmitting(true);
    
    // Check if any of the fields are empty
    const fields = ['name', 'email', 'phone', 'department', 'message'];
    for (const field of fields) {
      if (!formRef.current[field].value) {
        setErrorMessage('Please fill out all fields');
        setSubmitting(false); // Re-enable the button if validation fails
        return;
      }
    }

    // If all fields are filled, send the email
    emailjs
      .sendForm('service_girwh06', 'template_q1wh0lc', e.target, 'nk1ZZwdR1WkFqw1yn')
      .then(
        (result) => {
          console.log(result.text);
          setSuccessMessage('Form submitted successfully!');
          setErrorMessage('');
          formRef.current.reset(); // Reset form fields
        },
        (error) => {
          console.log(error.text);
          setErrorMessage('Too many requests, please try again later.');
          setSuccessMessage('');
        }
      )
      .finally(() => {
        setSubmitting(false); // Re-enable the button after the submission result
      });
  };

  return (
    <div>
      <div id="contact">
        <div className="container contact-us-content">
          <div className="contact-form">
            <div className="contact-row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit} ref={formRef}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone Number"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        id="department"
                        name="department"
                        className="form-control"
                        required
                      >
                        <option value="">Select a department</option>
                        {departments.map((department) => (
                          <option key={department} value={department}>
                            {department}
                          </option>
                        ))}
                      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    placeholder="Message"
                    required
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                {/* Disable button while submitting */}
                <button type="submit" className="btn btn-custom btn-lg" disabled={submitting}>
                  {submitting ? 'Sending...' : 'Send Message'}
                </button>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
              </form>
            </div>
          </div>
          <div className="contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span></p>
                <div className='contact-address'>
                  <p className='address1'>
                {props.data ? props.data.address : "loading"}
              </p>
              <p className='address2'>
                
                {props.data ? props.data.address2 : "loading"}
              </p>
              </div>
            </div>
            <div className="contact-item">
              <div className='contact-address contact-misc'>
              <p className='address1 field1'>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
              <p className='address2 field2'>
                <span>
                   CIN Number
                </span>{" "}
                U62011HR2024PTC121414
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Strategyz Inc.
              All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
